.changelog-popover-container {
  .changelog-popover-btn-container {
    width: 48px;
    height: 48px;
    position: fixed;
    left: 20px;
    bottom: 20px;

    .changelog-popover-btn.custom-button.left-icon, .changelog-popover-btn.custom-button {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: #6800d9;
      padding: 0;
      border: 0;

      @include btn-primary();
  
      svg {
        width: 24px;
        height: 24px;
        margin-right: 0;
      }
    }

    .items-count-badge {
      position: fixed;
      left: 52px;
      bottom: 52px;
      background-color: $link-500;
      padding: 2px 6px;
      line-height: 16px;
      border-radius: 12px;
      color: $white;
      font-weight: 500;
      cursor: pointer;
    }
  }
}

.changelog-popover {
  position: fixed;
  left: 11px;
  bottom: 83px;
  margin-right: 10px;
  z-index: 10000;

  opacity: 0;
  user-select: none;
  pointer-events: none;
  transition: opacity 100ms ease-in-out;

  max-width: 350px;
  max-height: 400px;
  background-color: $white;
  box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);
  border: 1px solid $neutral-200;
  border-radius: 8px;

  &::before {
    content: "";
    position: absolute;
    bottom: -12px;
    left: 20px;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid $neutral-200;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 22px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
  }

  &.show {
    opacity: 1;
    user-select: auto;
    pointer-events: all;
  }

  .changelog-popover-header {
    background-color: white;
    padding: 8px 16px;
    color: $neutral-700;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid $neutral-200;
    font-family: 'Roboto', sans-serif !important;
  }

  .changelog-popover-body {
    .no-items {
      height: 150px;
      width: 350px;
      padding: 60px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
    }

    .items-loading {
      height: 150px;
      width: 350px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .items-container {
      max-height: 350px;
      overflow-y: auto;

      .changelog-item {
        padding: 8px 16px;

        &:not(:last-child) {
          border-bottom: 1px solid $neutral-200;
        }

        .item-header {
          .type {
            float: left;
            margin-right: 6px;
            line-height: 12px;
            color: $white;
            font-weight: 500;
            padding: 2px 4px;
            font-size: 12px;
            border-radius: 2px;

            &.new {
              background-color: $success-500;
            }

            &.improve {
              background-color: $link-500;
            }

            &.fix {
              background-color: $primary-500;
            }
          }
          
          .title {
            float: none;
            margin-bottom: 0;
            font-size: 15px;
            font-weight: 500;
            line-height: 17px;
          }

          .clearfix {
            clear: both;
          }
        }

        .item-body {
          margin-top: 8px;
          
          .description {
            margin-bottom: 0;
            font-size: 14px;
            line-height: 18px;
            color: $neutral-700;
          }
        }
      }
    }
  }
}